var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getRole)?_c('v-container',{staticClass:"grey lighten-5 min-h-100 px-4",attrs:{"fluid":""}},[(_vm.getRole.id)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"12","cols":"12","sm":"8","xs":"12"}},[_c('v-card-title',[_vm._v(" Visicardia Users "),_c('v-spacer'),(_vm.getRole.id == _vm.hradmin)?_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":_vm.goToCreateCardsTemplate}},[_vm._v(" Create Users Card Template ")]),_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary"},on:{"click":function($event){{
                _vm.$router.push({
                  name: 'AddUsers',
                });
              }}}},[_vm._v(" Add Users ")]),_c('input',{ref:"selectCSV",staticClass:"mr-5 d-none",attrs:{"accept":".csv","type":"file"},on:{"change":_vm.importUserCsv}}),(_vm.csvfileloading)?_c('v-progress-circular',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_c('v-btn',{staticClass:"secondary mr-3",on:{"click":function($event){_vm.$refs.selectCSV &&
                _vm.$refs.selectCSV.click &&
                _vm.$refs.selectCSV.click()}}},[_c('v-icon',[_vm._v(" fa-upload ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Select CSV file")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadCsvTemplete}},[_vm._v(" Download csv templete ")])],1):_vm._e()],1),_c('vue-good-table',{attrs:{"columns":_vm.headers,"rows":_vm.getUsers,"line-numbers":true,"styleClass":"vgt-table striped","pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 10,
          position: 'bottom',
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: true,
          jumpFirstOrLast: false,
          firstLabel: 'First',
          lastLabel: 'Last',
          nextLabel: 'Next',
          prevLabel: 'Prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All',
          infoFn: (params) =>
            `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
        },"sort-options":{
          enabled: true,
          initialSortBy: { field: 'name', type: 'asc' },
        },"search-options":{
          enabled: true,
          trigger: 'enter',
          placeholder: 'Search',
        }},on:{"on-row-click":_vm.handleClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[(_vm.getRole.id == _vm.superadmin || _vm.getRole.id == _vm.hradmin)?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(props)}}},[_vm._v(" fa-pen-square ")]),_c('span',{staticClass:"mx-2"}),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(props)}}},[_vm._v(" fa-trash-alt ")])],1):_vm._e()]):_vm._e()]}}],null,false,388488048)},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v("No Data Found.")])])],1),(_vm.activeAllTab != 'Users' && _vm.$route.params.doc)?_c('v-col',{attrs:{"cols":"6"}},[_c('router-view')],1):_vm._e(),(_vm.editUser)?_c('v-dialog',{attrs:{"width":"400px","height":"400px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit User - "+_vm._s(_vm.editUser.name))]),(
            _vm.editUser.password ||
            _vm.editUser.changedPassword ||
            _vm.editUser.registeredPassword
          )?_c('v-card-subtitle',[_vm._v(" Password: "+_vm._s(_vm.decryptPassword(_vm.editUser))+" ")]):_vm._e(),_c('v-card-text',{staticClass:"my-10"},[_c('v-switch',{attrs:{"label":`Account Disable`},on:{"change":($event) => _vm.disableAuthAccount($event)},model:{value:(_vm.editUser.disabled),callback:function ($$v) {_vm.$set(_vm.editUser, "disabled", $$v)},expression:"editUser.disabled"}}),(!_vm.editUser.emailVerified)?_c('v-switch',{attrs:{"label":`Verify Email`},on:{"change":($event) => _vm.emailVerifiedAuthAccount($event)},model:{value:(_vm.editUser.emailVerified),callback:function ($$v) {_vm.$set(_vm.editUser, "emailVerified", $$v)},expression:"editUser.emailVerified"}}):_vm._e(),(_vm.getRole.id == _vm.superadmin && _vm.editUser.companyId)?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-select',{attrs:{"value":_vm.editUser['roleId'],"items":[
                { id: null, role: 'None' },
                ..._vm.getRoles.filter((role) => role.id != _vm.superadmin),
              ],"label":"Select Role","item-text":"role","item-value":"id"},on:{"change":($event) => {
                  _vm.editUser['roleId'] = $event;
                }}})],1):_vm._e(),_c('v-text-field',{attrs:{"label":"Designation"},model:{value:(_vm.editUser.designation),callback:function ($$v) {_vm.$set(_vm.editUser, "designation", $$v)},expression:"editUser.designation"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.updateUserClick}},[_vm._v(" Update ")])],1)],1)],1):_vm._e(),(_vm.deleteUser)?_c('v-dialog',{attrs:{"width":"400px","height":"400px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete User - "+_vm._s(_vm.deleteUser.name))]),_c('v-card-subtitle',{staticClass:"mt-0 mb-5"},[_vm._v(" Are you sure want to delete this account? this user will loose his account data, cards and contacts forever. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){{
                _vm.deleteDialog = false;
                _vm.deleteUser = null;
              }}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){{
                _vm.deleteDialog = false;
                _vm.confirmDeleteDialog = true;
              }}}},[_vm._v(" Yes ")])],1)],1)],1):_vm._e(),(_vm.deleteUser)?_c('v-dialog',{attrs:{"width":"400px","height":"400px"},model:{value:(_vm.confirmDeleteDialog),callback:function ($$v) {_vm.confirmDeleteDialog=$$v},expression:"confirmDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirm Delete User - "+_vm._s(_vm.deleteUser.name))]),_c('v-card-subtitle',{staticClass:"mt-0 mb-5"},[_vm._v(" After delete you can redo this user data. it will be permanently delete this user data. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){{
                _vm.confirmDeleteDialog = false;
                _vm.deleteUser = null;
              }}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.confirmDeleteUserClick}},[_vm._v(" Delete ")])],1)],1)],1):_vm._e(),(_vm.deleteUser)?_c('v-dialog',{attrs:{"width":"400px","height":"400px"},model:{value:(_vm.revertDeleteDialog),callback:function ($$v) {_vm.revertDeleteDialog=$$v},expression:"revertDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("User - "+_vm._s(_vm.deleteUser.name))]),_c('v-card-subtitle',{staticClass:"mt-0 mb-5"},[_vm._v(" You have requested your account deletion and it will be deleted on "+_vm._s(_vm.showRevertDate(_vm.deleteUser.promptDeleteTimeStamp))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":function($event){{
                _vm.revertDeleteDialog = false;
                _vm.deleteUser = null;
              }}}},[_vm._v(" Ok ")]),(new Date().getTime() < _vm.deleteUser.promptDeleteTimeStamp)?_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.revertDeleteUserClick}},[_vm._v(" Revert ")]):_vm._e()],1)],1)],1):_vm._e()],1):(!_vm.getRole.id || _vm.getRole.id === 0)?_c('v-row',[_c('v-col',[_vm._v("You are not an Authorized user")])],1):_c('v-row',[_c('v-col',{staticClass:"col text-center"},[_vm._v("Authenticating...")])],1)],1):(!_vm.getRole.id || _vm.getRole.id == null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v("You are not an Authorized user")])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_vm._v("Authenticating...")])])
}
var staticRenderFns = []

export { render, staticRenderFns }