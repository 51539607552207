<template>
  <v-container class="grey lighten-5 min-h-100 px-4" fluid v-if="getRole">
    <v-row no-gutters v-if="getRole.id">
      <v-col md="12" cols="12" sm="8" xs="12" class="mx-auto">
        <v-card-title>
          Visicardia Users
          <v-spacer></v-spacer>
          <div v-if="getRole.id == hradmin">
            <v-btn
              color="primary"
              class="mr-4"
              @click="goToCreateCardsTemplate"
            >
              Create Users Card Template
            </v-btn>

            <v-btn
              class="mr-5"
              color="primary"
              @click="
                {
                  $router.push({
                    name: 'AddUsers',
                  });
                }
              "
            >
              Add Users
            </v-btn>

            <input
              class="mr-5 d-none"
              ref="selectCSV"
              accept=".csv"
              type="file"
              @change="importUserCsv"
            />

            <v-progress-circular
              v-if="csvfileloading"
              class="my-10"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-btn
              v-else
              class="secondary mr-3"
              @click="
                $refs.selectCSV &&
                  $refs.selectCSV.click &&
                  $refs.selectCSV.click()
              "
            >
              <v-icon> fa-upload </v-icon>
              <span class="ml-2">Select CSV file</span>
            </v-btn>
            <v-btn color="primary" @click="downloadCsvTemplete">
              Download csv templete
            </v-btn>
          </div>
        </v-card-title>
        <vue-good-table
          :columns="headers"
          :rows="getUsers"
          :line-numbers="true"
          styleClass="vgt-table striped"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'bottom',
            perPageDropdown: [10, 20, 50, 100],
            dropdownAllowAll: true,
            jumpFirstOrLast: false,
            firstLabel: 'First',
            lastLabel: 'Last',
            nextLabel: 'Next',
            prevLabel: 'Prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
            infoFn: (params) =>
              `Showing ${params.firstRecordOnPage} - ${params.lastRecordOnPage} Of ${params.totalRecords}  ||  Page ${params.currentPage} / ${params.totalPage}`,
          }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'name', type: 'asc' },
          }"
          :search-options="{
            enabled: true,
            trigger: 'enter',
            placeholder: 'Search',
          }"
          @on-row-click="handleClick"
        >
          <div slot="emptystate">No Data Found.</div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <div
                v-if="getRole.id == superadmin || getRole.id == hradmin"
                @click.stop
              >
                <v-icon small @click="editItem(props)"> fa-pen-square </v-icon>
                <span class="mx-2"></span>
                <v-icon small @click="deleteItem(props)"> fa-trash-alt </v-icon>
              </div>
            </span>
          </template>
        </vue-good-table>
      </v-col>
      <v-col v-if="activeAllTab != 'Users' && $route.params.doc" cols="6">
        <router-view />
      </v-col>
      <v-dialog
        v-if="editUser"
        v-model="editDialog"
        width="400px"
        height="400px"
      >
        <v-card>
          <v-card-title>Edit User - {{ editUser.name }}</v-card-title>
          <v-card-subtitle
            v-if="
              editUser.password ||
              editUser.changedPassword ||
              editUser.registeredPassword
            "
          >
            Password: {{ decryptPassword(editUser) }}
          </v-card-subtitle>
          <v-card-text class="my-10">
            <v-switch
              v-model="editUser.disabled"
              @change="($event) => disableAuthAccount($event)"
              :label="`Account Disable`"
            ></v-switch>
            <v-switch
              v-if="!editUser.emailVerified"
              v-model="editUser.emailVerified"
              @change="($event) => emailVerifiedAuthAccount($event)"
              :label="`Verify Email`"
            ></v-switch>
            <div
              v-if="getRole.id == superadmin && editUser.companyId"
              @click.stop
            >
              <v-select
                :value="editUser['roleId']"
                :items="[
                  { id: null, role: 'None' },
                  ...getRoles.filter((role) => role.id != superadmin),
                ]"
                label="Select Role"
                item-text="role"
                item-value="id"
                @change="
                  ($event) => {
                    editUser['roleId'] = $event;
                  }
                "
              ></v-select>
            </div>
            <v-text-field
              v-model="editUser.designation"
              label="Designation"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue" text @click="updateUserClick"> Update </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="deleteUser"
        v-model="deleteDialog"
        width="400px"
        height="400px"
      >
        <v-card>
          <v-card-title>Delete User - {{ deleteUser.name }}</v-card-title>
          <v-card-subtitle class="mt-0 mb-5">
            Are you sure want to delete this account? this user will loose his
            account data, cards and contacts forever.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              text
              @click="
                {
                  deleteDialog = false;
                  deleteUser = null;
                }
              "
            >
              No
            </v-btn>
            <v-btn
              color="blue"
              text
              @click="
                {
                  deleteDialog = false;
                  confirmDeleteDialog = true;
                }
              "
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="deleteUser"
        v-model="confirmDeleteDialog"
        width="400px"
        height="400px"
      >
        <v-card>
          <v-card-title
            >Confirm Delete User - {{ deleteUser.name }}</v-card-title
          >
          <v-card-subtitle class="mt-0 mb-5">
            After delete you can redo this user data. it will be permanently
            delete this user data.
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              text
              @click="
                {
                  confirmDeleteDialog = false;
                  deleteUser = null;
                }
              "
            >
              Cancel
            </v-btn>
            <v-btn color="blue" text @click="confirmDeleteUserClick">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="deleteUser"
        v-model="revertDeleteDialog"
        width="400px"
        height="400px"
      >
        <v-card>
          <v-card-title>User - {{ deleteUser.name }}</v-card-title>
          <v-card-subtitle class="mt-0 mb-5">
            You have requested your account deletion and it will be deleted on
            {{ showRevertDate(deleteUser.promptDeleteTimeStamp) }}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue"
              text
              @click="
                {
                  revertDeleteDialog = false;
                  deleteUser = null;
                }
              "
            >
              Ok
            </v-btn>
            <v-btn
              v-if="new Date().getTime() < deleteUser.promptDeleteTimeStamp"
              color="blue"
              text
              @click="revertDeleteUserClick"
            >
              Revert
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-else-if="!getRole.id || getRole.id === 0">
      <v-col>You are not an Authorized user</v-col>
    </v-row>
    <v-row v-else>
      <v-col class="col text-center">Authenticating...</v-col>
    </v-row>
  </v-container>
  <div v-else-if="!getRole.id || getRole.id == null" class="row">
    <div class="col">You are not an Authorized user</div>
  </div>
  <div v-else class="row">
    <div class="col text-center">Authenticating...</div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import SidebarAll from '../components/sidebar-all.vue';
import headers from '../components/Navbar.vue';
import { format } from 'date-fns';
import firebase from 'firebase/app';
import { decrypt } from '../services/encryption_service.js';

export default {
  name: 'UserCollections',
  data() {
    return {
      search: '',
      activeAllTab: 'Users',
      activeTableData: [],
      drawer: true,
      editUser: null,
      editDialog: false,
      deleteDialog: false,
      confirmDeleteDialog: false,
      revertDeleteDialog: false,
      deleteUser: null,
      currentPage: 10,
      csvfileloading: false,
      expanded: [
        { text: 'UID', value: 'uid', sortable: false },
        { text: 'Banner Url', value: 'bannerUrl', sortable: false },
      ],
      singleExpand: false,
      headers: [
        { label: 'Name', align: 'start', sortable: true, field: 'name' },
        { label: 'Id', align: 'start', sortable: true, field: 'uid' },
        {
          label: 'Create Date',
          align: 'start',
          sortable: true,
          field: this.formatDate,
        },
        { label: 'Email', field: 'email', sortable: true },
        { label: 'Phone', field: 'phone', sortable: true },
        { label: 'Provider', field: 'provider', sortable: true },
        { label: 'Visicardia Handle', field: 'handle', sortable: true },
        {
          label: this.getRole?.id == this.hradmin ? 'Designation' : 'Company',
          field: this.getCompanyFromId,
          sortable: false,
        },
        ...(this.getRole?.id != this.hradmin
          ? [
              {
                label: 'Role',
                field: this.getRoleFromId,
                sortable: false,
              },
            ]
          : []),
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          width: '75px',
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('fetchCompanies', { self: this });
   
  },
  components: { SidebarAll, headers },
  computed: {
    ...mapGetters([
      'getUsers',
      'getRole',
      'getSearchText',
      'getCompanies',
      'getRoles',
    ]),
    ...mapState({
      admin: (state) => state.admin,
      hradmin: (state) => state.hradmin,
      superadmin: (state) => state.superadmin,
      currentUser: (state) => state.user,
    }),
  },
  methods: {
    ...mapMutations(['setSelectedUser', 'setUsers']),
    ...mapActions(['fetchAllRecords', 'updateUser', 'fetchCompany']),
    formatDate(value) {
      if (value.createDate) {
        return format(new Date(value.createDate), 'dd MMM yyyy hh:mm a');
      }
      return '';
    },
    decryptPassword(user) {
      // Cev3yoF3
      return decrypt(
        user.uid,
        user.companyId,
        user.password || user.changedPassword || user.registeredPassword,
        false
      );
    },
    async emailVerifiedAuthAccount() {
      this.editDialog = false;
      console.log(this.editUser.uid);
      const httpsCallable = firebase
        .functions(firebase.app())
        .httpsCallable('updateAuthUser');
      const result = await httpsCallable({
        uid: this.editUser.uid,
        update_json: {
          emailVerified: true,
        },
      });
      this.updateUser({
        uid: this.editUser.uid,
        updateMap: {
          emailVerified: true,
        },
      });
      this.editUser = null;
    },
    async disableAuthAccount(event) {
      this.editDialog = false;
      console.log(event || false, this.editUser.uid);
      const httpsCallable = firebase
        .functions(firebase.app())
        .httpsCallable('updateAuthUser');
      const result = await httpsCallable({
        uid: this.editUser.uid,
        update_json: {
          disabled: event || false,
        },
      });
      this.updateUser({
        uid: this.editUser.uid,
        updateMap: {
          disabled: event || false,
        },
      });
      this.editUser = null;
    },
    showRevertDate(timestamp) {
      if (timestamp) {
        return format(new Date(timestamp), 'dd MMM yyyy');
      }
      return '';
    },
    decryptPassword(user) {
      // Cev3yoF3
      return decrypt(
        user.uid,
        user.companyId,
        user.password || user.changedPassword || user.registeredPassword,
        false
      );
    },
    async emailVerifiedAuthAccount() {
      this.editDialog = false;
      console.log(this.editUser.uid);
      const httpsCallable = firebase
        .functions()
        .httpsCallable('updateAuthUser');
      const result = await httpsCallable({
        uid: this.editUser.uid,
        update_json: {
          emailVerified: true,
        },
      });
      this.updateUser({
        uid: this.editUser.uid,
        updateMap: {
          emailVerified: true,
        },
      });
      this.editUser = null;
    },
    async disableAuthAccount(event) {
      this.editDialog = false;
      console.log(event || false, this.editUser.uid);
      const httpsCallable = firebase
        .functions()
        .httpsCallable('updateAuthUser');
      const result = await httpsCallable({
        uid: this.editUser.uid,
        update_json: {
          disabled: event || false,
        },
      });
      this.updateUser({
        uid: this.editUser.uid,
        updateMap: {
          disabled: event || false,
        },
      });
      this.editUser = null;
    },
    getRoleFromId(rowObj) {
      if (
        this.getRole?.id == this.admin ||
        this.getRole?.id == this.superadmin
      ) {
        const list = this.getRoles.filter((item) => item.id == rowObj.roleId);
        if (list.length > 0) {
          return list[0].role;
        }
      }
      return '';
    },
    getCompanyFromId(rowObj) {
      if (
        this.getRole?.id == this.admin ||
        this.getRole?.id == this.superadmin
      ) {
        const list = this.getCompanies.filter(
          (item) => item.id == rowObj.companyId
        );
        if (list.length > 0) {
          return list[0].name;
        }
      } else if (this.getRole?.id == this.hradmin) {
        return rowObj['designation'];
      }
      return '';
    },
    handleClick(params) {
      if (params.row.uid && this.getRole?.id != null)
        this.$router.push('user/' + params.row.uid);
    },
    onSearch() {},
    async setActiveAllTab(link) {
      this.activeAllTab = link.value;
      if (link.value != 'Users') {
        await this.fetchAllRecords(link);
      } else {
        this.users = this.getUsers;
      }
    },
    editItem(item) {
      this.editDialog = true;
      this.editUser = item.row;
    },
    deleteItem(item) {
      console.log('delete User', item);
      const user = item.row;
      const currentDate = new Date().getTime();
      if (
        user.promptDeleteTimeStamp != null &&
        user.promptDeleteTimeStamp > 0
      ) {
        console.log(
          currentDate,
          user.promptDeleteTimeStamp,
          currentDate - user.promptDeleteTimeStamp
        );
        this.revertDeleteDialog = true;
        this.deleteUser = user;
      } else {
        this.deleteDialog = true;
        this.deleteUser = user;
      }
    },
    revertDeleteUserClick() {
      this.revertDeleteDialog = false;
      const list = this.getUsers.map((user) => {
        if (user.uid !== this.deleteUser.uid) {
          return user;
        } else {
          user.promptDeleteTimeStamp = null;
          return user;
        }
      });
      this.setUsers(list);
      this.updateUser({
        uid: this.deleteUser.uid,
        updateMap: { promptDeleteTimeStamp: null },
      });
      this.deleteUser = null;
    },
    confirmDeleteUserClick() {
      const currentDate = new Date().getTime();
      // const nextDate = new Date(currentDate);//5ullxrIANSagNSGPWiTDCyruck72
      const nextDate = new Date(currentDate + 2 * 24 * 60 * 60 * 1000);
      this.confirmDeleteDialog = false;
      const list = this.getUsers.map((user) => {
        if (user.uid !== this.deleteUser.uid) {
          return user;
        } else {
          user.promptDeleteTimeStamp = nextDate.getTime();
          return user;
        }
      });
      this.setUsers(list);
      this.updateUser({
        uid: this.deleteUser.uid,
        updateMap: { promptDeleteTimeStamp: nextDate.getTime() },
      });
      this.deleteUser = null;
    },
    updateUserClick() {
      this.editDialog = false;
      const updateUserMap = {
        designation: this.editUser.designation || '',
        roleId: this.editUser.roleId,
      };
      this.updateUser({ uid: this.editUser.uid, updateMap: updateUserMap });
      this.editUser = null;
    },
    async downloadCsvTemplete() {
      this.company = await this.fetchCompany(this.currentUser.companyId);
      const commonPassword = this.company.commonPassword;
      const items = [
        {
          name: 'abc',
          email: `abc@${this.company.emailFormat}.com`,
          countryCode: '+91',
          phone: '9876543210',
          designation: 'junior post',
          // password: commonPassword,
        },
      ];
      const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(','), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        ),
      ].join('\r\n');

      console.log(csv);

      var downloadLink = document.createElement('a');
      var blob = new Blob(['\ufeff', csv]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'UserVisicardiaTemplete.csv'; //Name the file here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async importUserCsv(event) {
      if (event?.target?.files?.length) {
        this.csvfileloading = true;
        const companyId = this.currentUser.companyId;
        const folder = `company/${companyId}`;
        const csvfileName = `${new Date().getTime()}-users.csv`;

        const storageRef = firebase
          .storage()
          .ref(`${folder}/${csvfileName}`)
          .put(event.target.files[0]);

        let url;

        await storageRef.then(
          async (snapshot) => {
            switch (snapshot.state) {
              case firebase.storage.TaskState.SUCCESS:
                url = await snapshot.ref.getDownloadURL();
                console.log('url ', url);
                break;
              default:
                break;
            }
          },
          (error) => {
            console.log(error.message);
          }
        );

        if (JSON.parse(process.env.NODE_ENV) == 'development') {
          firebase.functions().useEmulator('localhost', 5001);
        }

        const httpsCallable = firebase
          .functions()
          .httpsCallable('csvToJsonUser');

        const result = await httpsCallable({
          folder,
          csvfileName,
          url,
          companyId,
        });
        this.csvfileloading = false;

        if (result.data && result.data.length) {
          this.$router.push({
            name: 'AddUsers',
            params: {
              data: {
                users: result.data,
              },
            },
          });
        }
      }

      event.target.value = null;
    },
    goToCreateCardsTemplate() {
      this.$router.push('/create-cards-template');
    },
  },
  mounted() {
    if (this.getUsers && this.getUsers.length) {
      this.users = this.getUsers;
      
    } else {
      this.users = [];
    }
  },
};
</script>
<style lang="scss">
.min-h-100 {
  min-height: 100vh;
}
</style>
