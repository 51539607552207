<template>
    <v-navigation-drawer
    fixed
    style="z-index: 4; width: 150px"
   >
      <v-list
        dense
        nav
        style="padding-top: 10rem"
        
      >
       <v-list-item-group active-class="deep-purple--text text--accent-4">
         
         <!-- <v-divider class="my-2" :key="'divider_' + index" /> -->
          <template  v-for="(item, index)  in items">
            <v-list-item
              :key="item.name"
              @click="$emit('setActiveAllTab', item)"
            >
              <v-list-item-content>
                  <v-list-item-title class="list-t-item" :class="
                      activeAllTab == item.name
                      ? 'deep-purple--text text--accent-4'
                      : 'grey--text'
                    " >{{ item.name }}
                  </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2" :key="'divider_' + index" />
           
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  <!-- </v-card> -->
</template>
<script>
  export default {
    props:{
      activeAllTab:  String,
    },
    data () {
      return {
        
        items: [
         {
           name: "Users",
           value: "Users"
         }, 
        {
          name: "Cards",
          value: "Cards"
        },
        {
          name: "Received/Shared",
          value: "Received"
        },
        {
          name: "Pending",
          value: "Received"
        },
        {
          name: "Permissions",
          value: "Permissions"
        },
        {
          name: "Requests",
          value: "Requests"
        },
        {
          name: "Invites",
          value: "Invites"
        },
        ],
        right: null,
        drawer: true,
      }
    },
    methods: {

    },
  }
</script>